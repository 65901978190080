<template class="main-login-section">
  <div class="sign-in-container">
       <div class="logo">
        <img src="@/assets/images/logo.png" alt="logo" height="50vh" />
      </div>
    <div class="sign-in-content">
      <div>
        <div class="sign-in-title text-center">
          <h3 class="mb-0 title">Create Account</h3>
        </div>
        <div class="sign-in-description text-center">
          <p>Enter your email address and password to discover more</p>
        </div>
      </div>

      <sign-up-form class="sign-in-form"></sign-up-form>
    </div>
  </div>
</template>
<script>
import SignUpForm from './Forms/SignUpForm'

export default {
  name: 'SignUp1',
  components: { SignUpForm },
  data: () => ({}),
  methods: {}
}
</script>
