<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <p class="text-center text-red my-n2 py-n2">
      {{
        authUser.error && authUser.error.non_field_errors
          ? authUser.error.non_field_errors[0]
          : ""
      }}
    </p>
    <form
      class="mt-4 form-texts"
      novalidate
      @submit.prevent="handleSubmit(onSubmit)"
    >
      <ValidationProvider
        vid="name"
        name="username"
        rules="required"
        v-slot="{ errors }"
      >
        <div class="form-group">
          <label for="name">Your username <span><small class="text-black">(No spaces)</small></span></label>
          <input
            type="text"
            v-model="user.username"
            :class="
              'form-control mb-0' + (errors.length < 0 ? ' is-invalid' : '')
            "
            id="name"
            aria-describedby="emailHelp"
            placeholder="What shall we call you?"
          />
          <div class="">
            <span>{{ errors[0] }}</span>
            <span v-if="authUser.error">
              <p v-for="error in authUser.error.username" :key="error">
                {{ error }}<br />
              </p>
            </span>
          </div>
        </div>
      </ValidationProvider>
      <ValidationProvider
        vid="name"
        name="Email"
        rules="required|email"
        v-slot="{ errors }"
      >
        <div class="form-group">
          <label for="email">Email address</label>
          <input
            type="email"
            v-model="user.email"
            :class="
              'form-control mb-0' + (errors.length < 8 ? ' is-invalid' : '')
            "
            id="email"
            aria-describedby="emailHelp"
            placeholder="Your email"
          />
          <div class="">
            <span>{{ errors[0] }}</span>
            <p v-if="authUser.error">
              <small v-for="error in authUser.error.email" :key="error"
                >{{ error }}<br
              /></small>
            </p>
          </div>
        </div>
      </ValidationProvider>
      <ValidationProvider
        vid="password"
        name="Password"
        rules="required|min:6"
        v-slot="{ errors }"
      >
        <div class="form-group">
          <label for="password">Password</label>
          <input
            :type="textPassword ? 'text' : 'password'"
            v-model="user.password1"
            :class="
              'form-control mb-0 password-input' +
                (errors.length > 0 ? ' is-invalid' : '')
            "
            id="password"
            placeholder="Password"
          />
          <i
            class="las la-eye-slash cursor-pointer float-right"
            @click="textPassword = !textPassword"
          ></i>
          <div class="">
            <span>{{ errors[0] }}</span>
            <p v-if="authUser.error">
              <small v-for="error in authUser.error.password1" :key="error"
                >{{ error }} <br
              /></small>
            </p>
          </div>
        </div>
      </ValidationProvider>
      <div class=" w-100 hidden">
        <div class="custom-control custom-checkbox d-inline-block mt-2 pt-1">
          <input
            type="checkbox"
            class="custom-control-input"
            id="customCheck1"
          />
          <label class="custom-control-label" for="customCheck1"
            >I accept Terms and Conditions</label
          >
        </div>
      </div>
      <div class="sign-info text-center sign-in-button">
        <button type="submit" class=" d-block w-100 mb-2 sign-in-form-btn">
          Sign Up
        </button>
        <span class="text-dark d-inline-block line-height-2"
          >Already Have Account ?
          <router-link to="/auth/sign-in" class="text-black font-weight-bold"
            >Log In</router-link
          ></span
        >
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SignUpForm',
  components: {},
  props: [],
  computed: {
    ...mapGetters({
      // eslint-disable-next-line no-constant-condition
      authUser: 'Setting/authUserState'
    })
  },
  data: () => ({
    user: {
      username: '',
      email: '',
      password1: '',
      password2: ''
    },
    textPassword: false
  }),
  created () {
    this.goHome()
  },
  methods: {
    onSubmit () {
      this.user.password2 = this.user.password1
      this.$store.dispatch('Setting/addUserAction', this.user)
    },
    goHome () {
      if (this.authUser.auth) {
        if (this.$route.query.redirect) {
          this.$router.push(this.$route.query.redirect)
        } else {
          this.$router.push({ name: 'shop.home' })
        }
      }
    }
  }
}
</script>

<style>
.password-input {
  padding-right: 30px;
}
</style>
